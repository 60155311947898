@import '../../palette';

.tasklist {
  overflow: auto;

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 99.99%;
    th {
      @include themed() {
        background-color: t($todo-table-header-background);
      }
      padding: 8px 16px;
    }
    th,
    td {
      @include themed() {
        border: 1px solid t($border);
      }
      text-align: left;
      .values {
        align-items: center;
        display: flex;

        .value {
          align-items: center;
          display: flex;
          flex-flow: row nowrap;
          .icon {
            height: 16px;
            width: 16px;
            margin: 0 4px 0 0;
          }
        }
        .value + .value {
          margin: 0 0 0 16px;
        }
      }
      .integration-button {
        align-items: center;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        text-align: center;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.is-activated {
          background-color: $hansoft-green;
          border-radius: 4px;
          padding: 8px 16px;
          &:hover {
            background-color: lighten($hansoft-green, 5%);
          }
        }

        &.is-deactivated {
          @include themed() {
            background-color: t($itemdetails-disabled-text);
          }
          border-radius: 4px;
          padding: 8px 16px;
          &:hover {
            @include themed() {
              background-color: lighten(t($itemdetails-disabled-text), 5%);
            }
          }
        }
      }
      .description {
        display: flex;
        flex-flow: column nowrap;
        .project {
          display: flex;
          color: $gray;
          font-size: 12px;
        }

        a {
          @include themed() {
            color: t($text);
          }
        }
      }

      .DueDate {
        display: flex;
        align-items: flex-start;
        flex-flow: column nowrap;
        justify-content: center;
        .start-date {
          display: flex;
          color: $gray;
          font-size: 12px;
        }
      }
      .singleselect-container .singleselect {
        background-color: transparent;
        border: 1px solid transparent;
        padding: 8px 16px;
      }
      &:not(.no-padding) {
        padding: 8px 16px;
      }
      &.group {
        @include themed() {
          border-bottom: 1px solid t($border) !important;
          border-top: 1px solid t($border) !important;
        }
        color: $hansoft-green;
        font-size: 16px;
        font-weight: 700;
        padding: 16px;
      }
      &:not(.is-wide) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &.is-wide {
        width: 40%;
        max-width: 40%;
      }
      &.is-narrow {
        width: 10%;
        max-width: 10%;
      }
    }
    td {
      &:not(.is-disabled) {
        cursor: pointer;
        &:hover {
          @include themed() {
            background-color: t($hover);
          }
        }
      }
    }
    tbody {
      margin: 0 0 48px 0;
    }
  }
}

.description-cell {
  display: flex;
  &:hover {
    @include themed() {
      background-color: t($hover);
    }
    cursor: pointer;
    .taskname {
      text-decoration: underline;
    }
  }
}

.todo {
  table {
    td {
      border: 1px solid transparent !important;
      display: flex;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 512px) {
  .todo {
    .tasklist {
      td:not(.is-wide) {
        white-space: initial !important;
      }
    }

    table {
      th.DueDate,
      th.Priority,
      th.WorkRemaining,
      td.DueDate,
      td.Priority,
      td.WorkRemaining {
        display: none;
        visibility: hidden;
        max-width: 0;
        width: 0;
      }
      th.Description {
        width: 40%;
      }
      th.Status {
        width: 60%;
      }
    }
  }
}

@import '../../../palette';

.app {
  height: 100vh;
}

.modal-background {
  border-radius: 8px;
  @include themed() {
    background-color: t($background);
    color: t($text);
  }
  .modal-content {
    padding: 32px 24px;
  }
}

@media only screen and (max-width: 512px) {
  .ReactModal__Overlay {
    padding: 16px !important;
  }

  .taskcreate {
    .modal-content {
      padding: 16px;
      .field .label {
        min-width: 80px;
        width: 80px;
      }
    }
  }
}

.todo {
  @include themed() {
    background-color: t($background);
    color: t($text);
  }
  width: 100%;

  .list-loading-background {
    display: block;
    width: 99.99%;
  }
  max-width: 100%;
  height: calc(100vh - 56px);
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;

  .ui.basic.bottom.attached.segment {
    padding: 0;
    margin: 0;
  }

  .filter {
    @include themed() {
      border-bottom: 1px solid t($border);
    }

    padding: 8px 16px;
  }
}

.MyWork {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.MyWork-Banner {
  padding: 10pt;
  text-align: left;
  font-size: 24pt;
  font-weight: bold;
  margin: 0;
  vertical-align: text-bottom;
}

.MyWork-Menu {
  text-align: right;
  font-size: 12pt;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.itemdetails-field-container {
  margin: 4px 0 0 0;

  .is-disabled {
    @include themed() {
      color: t($itemdetails-disabled-text);
    }
  }
}

.singleselect-icon {
  height: 14px;
  margin: 3px 8px 0 0;
  width: 14px;
}

.singleselect-icon-component {
  height: 22px;
  margin: 4px 8px 0 0;
  width: 22px;
}
.singleselect-icon-component-container {
  display: flex;

  .singleselect-icon-component {
    height: 27px;
    margin: 4px 8px 0 0;
    width: 22px;
  }
  .icon-component-text {
    display: flex;
    align-items: center;
  }
}

.singleselect-container {
  height: 100%;

  .dropdown-icon {
    display: none;
  }
}

.singleselect-content {
  width: 100%;
}

.itemdetails-field {
  @include themed() {
    color: t($text);
  }
  margin: 0 0 16px 0;

  .itemdetailsfield-label {
    &.is-required {
      color: $light-red;
    }
  }
}

.input,
input {
  @include themed() {
    background-color: t($background);
    border: 1px solid t($border);
    color: t($text);
  }
  border-radius: 0;
  height: 30px;
  padding: 0 8px;
  width: 100%;

  &::selection {
    color: $pitch-black !important;
    background: $light-cyan !important;
  }
  &:focus {
    border: 1px solid $hansoft-green;
    outline: none;
  }
}

.input {
  align-items: center;
  display: flex;

  a {
    margin: 0 8px 0 0;
  }
}

.singleselect {
  align-items: center;
  @include themed() {
    background: t($background);
    border: 1px solid t($border);
    color: t($text);
  }
  cursor: pointer;
  display: flex;
  height: 30px; /* FIXME: Remove this once other fields are refactored */
  overflow: hidden;
  padding: 0 8px;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  &.is-disabled {
    @include themed() {
      color: t($itemdetails-disabled-text);
    }
    cursor: initial;
  }

  .span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.ItemDetail-Page {
  .singleselect {
    &.is-disabled {
      @include themed() {
        background-color: t($itemdetails-disabled);
        color: t($itemdetails-disabled-text);
      }
      cursor: initial;
    }
    &:not(.is-disabled):hover {
      @include themed() {
        background-color: t($hover);
      }
    }
  }
}

a {
  color: $blue;

  &:hover {
    color: $blue;
    text-decoration: underline;
  }
}

.floatingselection-list {
  min-height: 100px;
  max-height: 230px;
  overflow-y: auto;
  padding: 0;
}

.floatingselection-item {
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 8px 16px;
}

.floatingselection-seperator {
  background-color: $gray;
  height: 1px;
}

.floatingselection-item:hover {
  background-color: $white-cyan;
}

.floatingselection-item.is-selected {
  background-color: $very-light-cyan;
}

.floatingselection-item-icon {
  height: 14px;
  margin: 0 8px 0 0;
  width: 14px;
}

.js-floatingselection-item-name {
  display: flex;
  align-items: center;
}

.floatingselection-item-icon-component {
  height: 22px;
  margin: 4px 8px 0 0;
  width: 22px;
}

.floatingselection-item-name {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.floatingselection-item-subtext {
  color: $heavy-gray;
  font-size: 12px;
}

.floatingselection-item-name.can-wrap {
  white-space: initial;
}

.floatingselection-item-check {
  flex: 0 0 auto;
  height: 24px;
  margin: 0 0 0 8px;
  width: 24px;
}

.floatingselection-bottomarea {
  display: flex;
  justify-content: flex-end;
}

.floatingselection-buttonbox {
  display: flex;
  justify-content: flex-end;
  margin: 2px 8px;
}

.floatingselection-findfield {
  display: flex;
  justify-content: flex-start;
  margin: 8px 2px;
  border: 1px solid $very-light-gray;
}

.itemdetails-panes {
  flex: 1 1 auto;
  height: 100%;
  margin: 0 16px;
  overflow: hidden;
}

.itemdetails-panes .pane-content {
  margin: 8px 0;
}

.itemdetails-panes .pane-splitter {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  display: flex;
  justify-content: space-around;
  width: 8px !important;
  margin: 0 16px;
}

.itemdetails-panes .pane-splitter:hover .splitter-content {
  @include themed() {
    background-color: $hansoft-green;
  }
}

.itemdetails-panes .pane-splitter .splitter-content {
  @include themed() {
    background-color: t($border);
  }
  height: 100%;
  width: 1px;
}

.itemdetails-panes .importantfields {
  margin: 0 0 48px;
}

.releasetag {
  align-items: center;
  display: flex;
  margin: 0 0 4px;
}

.releasetag .releasetag-icon {
  align-items: center;
  display: flex;
  margin: 0 8px 0 0;
}

.releasetag .releasetag-text {
  color: $gray;
  font-size: 0.9em;
}

.commentcontrol-title {
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px 0;
}

/* FIXME: Move out */

.ui.popup {
  padding: 0;
}

.Pane.vertical {
  min-width: 1px;
}

.ui.secondary.inverted.pointing.menu .active.item {
  border-color: $hansoft-green;
}

table {
  height: 1px;
}

table .singleselect {
  height: 100%;
}

table .numberfield {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  padding: 8px 16px;
}

table .numberfield-edit {
  padding: 5px 0;
}

table .DueDate {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  padding: 8px 16px;
}

.lightbox.ui.modal {
  width: initial !important;
  margin: initial !important;
}
.lightbox .image.content {
  padding: initial !important;
}

// react Calendar
.rdtPicker {
  @include themed() {
    background: t($background);
    border: 1px solid t($border);
    color: t($text);
  }
  td.rdtDay:hover,
  .rdtSwitch:hover,
  td.rdtHour:hover,
  td.rdtMinute:hover,
  td.rdtSecond:hover,
  .rdtTimeToggle:hover,
  button:hover,
  .rdtPrev:hover,
  .rdtNext:hover,
  .rdtCounter .rdtBtn:hover,
  td.rdtMonth:hover,
  td.rdtYear:hover {
    @include themed() {
      background-color: t($hover);
    }
    cursor: pointer;
  }

  td.rdtActive,
  td.rdtActive:hover {
    @include themed() {
      background-color: $hansoft-green;
      color: t($text);
    }
  }

  td.rdtToday:before {
    @include themed() {
      border-bottom-color: $hansoft-green;
    }
  }
}

@import '../../../../../palette';

.show-options {
  margin: 0 8px 0 0;
  height: 100%;
  min-width: 60px;
  @include themed() {
    color: t($text);
  }
  &:hover {
    cursor: pointer;
  }

  .menu {
    left: initial;
    right: 0;
  }

  .icon {
    margin: 5px 0 0 5px !important;
  }
  .checkmark {
    margin-right: 5px !important;
    padding-left: 0 !important;
  }

  div.item {
    padding-left: 25px !important;
  }

  div.item-with-checkbox {
    padding-left: 0 !important;
  }
}

@import '../../../../palette';

.filterbar {
  @include themed() {
    background-color: t($background);
    border-top: 1px solid t($border);
    color: t($text);
  }
  display: flex;
  padding: 8px 8px;

  .filterbutton {
    &.is-active {
      .singleselect {
        color: $hansoft-green;
      }
    }
  }

  .filterbutton + .filterbutton {
    margin: 0 0 0 16px;
  }

  .singleselect-container {
    min-width: 200px;

    .select-view {
      display: flex;

      .dropdown-icon {
        display: flex !important;
        margin-left: auto;
      }
    }
  }

  input {
    margin: 0 16px 0 0;
  }

  .show-dropdown {
    margin-left: auto;
  }

  .clear-all {
    margin-left: 10px;
    min-width: 80px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    @include themed() {
      color: t($link);
    }
  }
}

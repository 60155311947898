@import '../../../palette';

.ItemDetail-Page {
  @include themed() {
    background-color: t($itemdetails-screen-background);
    color: t($text);
  }
  display: flex;
  flex-flow: column nowrap;
  height: calc(100% - 56px);

  .ui.borderless.inverted.menu {
    border-radius: 0;
  }

  .ui.segment {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .singleselect-container {
    min-width: 250px;

    .select-view {
      display: flex;

      .dropdown-icon {
        display: flex !important;
        margin-left: auto;
      }
    }
  }

  .ui.secondary.inverted.menu {
    .item {
      @include themed() {
        color: t($text) !important;
      }
    }
  }

  .milestones {
    display: flex;
  }

  .right-top {
    display: flex;
    flex-flow: row nowrap;
  }

  .description {
    display: flex;
    align-items: center;
    padding-right: 8px;
    margin: 0 16px 16px;
  }

  .sprint {
    flex: 0 0 auto;
    margin: 0 16px 24px;
  }
}

.cover-image {
  cursor: pointer;
  max-height: 60px;
  max-width: 60px;
  width: auto;
  height: auto;
  object-fit: contain;
  margin-right: 10px;
}

.tab-background {
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.ItemDetail-ButtonLink {
  color: $white;
  font-size: 1em;
  text-decoration: none;
}

#multiline_link .ui.secondary {
  overflow-x: auto;
  padding: 0 0 8px 0;
}

.itemdetail-topbar {
  @include themed() {
    background-color: t($itemdetails-screen-background);
  }
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0 0 16px 0;
  padding: 8px;

  .watch {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    svg {
      @include themed() {
        fill: t($text);
        stroke: t($text);
      }
      height: 16px;
      margin-right: 8px;
      width: 16px;
    }
    margin-right: 16px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.relationlist {
  cursor: pointer;
  height: 18px;
  margin: 0 8px 0;
  width: 18px;
}

.relationlist > image {
  height: 18px;
  width: 18px;
}

.relationlist-popup {
  display: flex;
  flex-flow: column nowrap;
  max-width: 384px;
  min-width: 264px;
}

.relationlist-popup .relationlist-items {
  max-height: 400px;
  overflow-y: auto;
  padding: 8px;
}

.relation {
  align-items: center;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.relation + .relation {
  margin: 8px 0 0 0;
}

.relation .relation-icon {
  align-items: center;
  display: flex;
  height: 16px;
  margin: 0 8px 0 0;
  width: 16px;
}

.relation .relation-text {
  font-size: 0.9em;
}

.relation .relation-subtext {
  font-size: 0.85em;
}

.relation .relation-link {
  color: $pitch-black;
  font-size: 1em;
}

.relation .relation-link:hover {
  text-decoration: underline;
}

.hansoftlink {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  margin: 0 16px 0 0;
  .icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
}

.hansoftlink:hover {
  text-decoration: underline;
}

.hansoftlink > img {
  height: 18px;
  margin: 0 8px 0 0;
  width: 18px;
}

.timespent-container {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}

.timespent-label {
  color: rgb(171, 172, 176);
  margin: 0 8px 0 0;
}

.timespent-input {
  flex: 1 1 auto;
}

/* Todo: move out Semantic overrides? */

#multiline_link .ui.secondary.menu {
  border: transparent !important;
}

#multiline_link .ui.secondary.menu .item {
  padding: 0 4px 8px !important;
}

.multiline-toolbar .rdw-option-wrapper {
  background: initial;
}

.multilinetoolbar-icon,
.multilinetoolbar-code .rdw-option-wrapper,
.multilinetoolbar-code .rdw-option-active {
  border: initial !important;
  @include themed() {
    background: t($itemdetails-toolbar-background);
    color: t($text);
  }
  height: 24px !important;
  min-width: initial !important;
  padding: 2px 4px !important;
  margin: 0 4px 0 0 !important;
}

.multilinetoolbar-icon.rdw-option-active,
.multilinetoolbar-code .rdw-option-active {
  box-shadow: initial;
  @include themed() {
    background: t($itemdetails-toolbar-hover) !important;
  }
}

.multilinetoolbar-code {
  padding: 0 !important;
}

.multilinetoolbar-icon .rdw-option-wrapper {
  align-items: center;
  display: flex;
  border: initial !important;
  background: initial;
  height: initial;
  min-width: initial;
  padding: 0;
}

.multilinetoolbar-icon .rdw-option-wrapper:hover,
.multilinetoolbar-code .rdw-option-wrapper:hover {
  background: initial;
  box-shadow: initial;
}

.multilinetoolbar-icon:hover,
.multilinetoolbar-code .rdw-option-wrapper:hover {
  @include themed() {
    background-color: t($itemdetails-toolbar-hover);
  }
  box-shadow: initial;
}

.multilinetoolbar-icon img {
  height: 16px;
  width: 16px;
}

.multiline-editor {
  @include themed() {
    background-color: t($background);
    border: 1px solid t($border);
  }
  .rdw-colorpicker-modal {
    color: $pitch-black;
  }
  .rdw-link-modal {
    color: $pitch-black !important;
  }
}

.multlilinetoolbar {
  @include themed() {
    background-color: t($itemdetails-toolbar-background);
  }
  border: initial !important;
  padding: initial !important;
}

.rdw-editor-main pre {
  @include themed() {
    color: t($text);
  }
  border: initial;
  background: initial;
  padding: initial;
}

.hs-list-item {
  margin-left: 2.5em !important;
}

.public-DraftStyleDefault-block,
.public-DraftStyleDefault-ul,
.public-DraftStyleDefault-ol {
  margin: 0 !important;
}

.rdw-inline-wrapper {
  order: 0;
}

.rdw-code-wrapper {
  order: 1;
}

.rdw-colorpicker-wrapper {
  order: 2;
}

.rdw-remove-wrapper {
  order: 3;
}

.rdw-list-wrapper {
  order: 4;
}

.rdw-link-wrapper {
  order: 5;
}

@media only screen and (max-width: 512px) {
  .vertical.reflex-container {
    flex-direction: column !important;
  }

  .reflex-splitter {
    display: none !important;
    visibility: hidden !important;
  }

  .reflex-element {
    flex: 1 1 auto !important;
  }

  .reflex-splitter + .reflex-element {
    margin-top: 32px;
  }
}

@import '../../../palette';

.toDoBoards {
  @include themed() {
    display: flex;
    flex-grow: 1;
    flex-flow: column nowrap;
    flex-direction: column;
    align-items: flex-start;
    background-color: t($background) !important;
  }
}

.container {
  @include themed() {
    height: calc(100vh - 56px);
    background-color: t($background) !important;
    overflow: auto;
  }
}

.kanban-board {
  width: 100%;
}
